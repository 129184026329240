import React, { useContext, useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import useApiCall from '../customHook/useApiCall';
import { UserAuthContext } from '../contexts/UserAuthContext';
import LoadingView from './LoadingView';
import TaskList from '../components/TaskList';
import QuickAddTask from '../components/QuickAddTask';
import { isEmpty } from 'lodash';
import { TaskListContext } from '../contexts/TaskListContext';
import { TaskDueRange } from '../utils/DateTimeUtils';
import {
  faCalendarDays,
  faCalendarPlus,
  faCalendarWeek,
  faCalendarXmark,
  faPoo,
  faStar,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

const TaskListView: React.FC = () => {
  const { loadingUserProfile } = useContext(UserAuthContext);
  const {
    overdueTasks,
    dueTodayTasks,
    dueThisWeekTasks,
    dueNextWeekTasks,
    futureTasks,
    dueUnknownTasks,
    setTasks,
  } = useContext(TaskListContext);
  const { data, loading, error } = useApiCall('/tasks', []);

  useEffect(() => {
    if (!isEmpty(data)) {
      setTasks(data);
    }
  }, [data]);

  return loadingUserProfile || loading ? (
    <LoadingView />
  ) : (
    <div
      className='main-container'
      style={{
        padding: '0 20px 0 20px',
        overflowY: 'auto',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '50px',
        paddingBottom: '80px',
        // backgroundColor: '#3B4044',
      }}
    >
      <Stack>
        <QuickAddTask />
        {!isEmpty(overdueTasks) && (
          <TaskList
            listIcon={faPoo}
            iconColor='#DC3444'
            title='Alarm! You have tasks Overdue!'
            titleColor='#DC3444'
            tasks={overdueTasks}
            taskDueRange={TaskDueRange.OVER_DUE}
          />
        )}

        <TaskList
          listIcon={isEmpty(dueTodayTasks) ? faStar : faTriangleExclamation}
          iconColor={isEmpty(dueTodayTasks) ? '#FFC007' : '#FFC007'}
          title={
            isEmpty(dueTodayTasks) ? 'WOW. Nothing is due today!' : 'Today'
          }
          titleColor={isEmpty(dueTodayTasks) ? '#FFC007' : '#FFC007'}
          tasks={dueTodayTasks}
          taskDueRange={TaskDueRange.TODAY}
        />

        <TaskList
          listIcon={isEmpty(dueThisWeekTasks) ? faStar : faCalendarWeek}
          iconColor={isEmpty(dueThisWeekTasks) ? '#FFC007' : '#14A2B8'}
          title={
            isEmpty(dueThisWeekTasks) ? 'All caught up this week' : 'This week'
          }
          titleColor={isEmpty(dueThisWeekTasks) ? '#FFC007' : '#14A2B8'}
          tasks={dueThisWeekTasks}
          taskDueRange={TaskDueRange.THIS_WEEK}
        />
        <TaskList
          listIcon={faCalendarDays}
          iconColor='#017BFE'
          title='Next week'
          titleColor='#017BFE'
          tasks={dueNextWeekTasks}
          taskDueRange={TaskDueRange.NEXT_WEEK}
        />
        <TaskList
          listIcon={faCalendarPlus}
          iconColor='#28A745'
          title='Coming up soon'
          titleColor='#28A745'
          tasks={futureTasks}
          taskDueRange={TaskDueRange.FUTURE}
        />
        {!isEmpty(dueUnknownTasks) && (
          <TaskList
            listIcon={faCalendarXmark}
            iconColor='#6C757D'
            title='No Due Day'
            titleColor='#6C757D'
            tasks={dueUnknownTasks}
            taskDueRange={TaskDueRange.UNKNOWN}
          />
        )}
      </Stack>
    </div>
  );
};

export default TaskListView;
