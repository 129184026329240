import React from 'react';
import { Header } from '../components/Header';
import SideNav from '../components/SideNav';
import TaskListView from './TaskListView';
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import CalendarView from './CalendarView';
import TaskListProvider from '../contexts/TaskListProvider';

const AppContainer: React.FC = () => {
  return (
    <div className='main-container'>
      <Header />
      <div className='main-container'>
        <Router>
          <div style={{ display: 'flex' }}>
            <SideNav />
            <Routes>
              <Route
                path='/'
                element={
                  <TaskListProvider>
                    <TaskListView />
                  </TaskListProvider>
                }
              />
              <Route
                path='/list'
                element={
                  <TaskListProvider>
                    <TaskListView />
                  </TaskListProvider>
                }
              />
              <Route path='/calendar' Component={CalendarView} />
            </Routes>
          </div>
        </Router>
      </div>
    </div>
  );
};

export default AppContainer;
