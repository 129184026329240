import React from 'react';
import workingOnIt from '../assets/images/computer-guy.png';
import styled from 'styled-components';

const CalendarView: React.FC = () => {
  return (
    <Container>
      <div>
        <StyledText>Working on it!</StyledText>
        <ScaledImage src={workingOnIt} alt='Working on it' />
      </div>
    </Container>
  );
};

const Container = styled.div({
  display: 'grid',
  width: '100%',
  placeItems: 'center',
});

const ScaledImage = styled.img({
  maxWidth: '600px',
  height: 'auto',
  borderRadius: '20px',
  boxShadow:
    '10px 10px 20px rgba(0, 0, 0, 0.19), 7px 7px 6px rgba(0, 0, 0, 0.23)',
});

const StyledText = styled.p({
  bottom: '1rem',
  textAlign: 'center',
  fontSize: '30px',
});

export default CalendarView;
