import { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { UserAuthContext } from './UserAuthContext';
import { ValidatorResult } from '@aws-amplify/ui';
import { getRequest, postRequest } from '../utils/Requests';
import { isEmpty } from 'lodash';
import { UserProfile } from '../model/UserProfile';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    },
  },
});

const UserAuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    undefined,
  );
  const [loadingUserProfile, setLoadingUserProfile] = useState<boolean>(false);

  const signUpFields = {
    signUp: {
      username: {
        placeholder: 'Enter your Email',
        isRequired: true,
        label: 'Email',
        order: 1,
      },
      family_name: {
        placeholder: 'Enter your Last Name here',
        isRequired: true,
        label: 'Last Name',
      },
      given_name: {
        placeholder: 'Enter your First Name here',
        isRequired: true,
        label: 'First Name',
      },
    },
    signIn: {
      username: {
        placeholder: 'Enter your Email',
        isRequired: true,
        label: 'Email',
        order: 1,
      },
    },
  };

  const components = {
    Header() {
      return (
        <div>
          <center>
            <h1>InTempore</h1>
            <p>Keep your tasks on time.</p>
          </center>
        </div>
      );
    },
    Footer() {
      return (
        <div>
          <center>GTech 2024</center>
        </div>
      );
    },
  };

  const loadUserProfile = async (userId?: string) => {
    setLoadingUserProfile(true);
    if (userId) {
      let userProfileResult = await getRequest({ uri: '/userProfile' });

      if (isEmpty(userProfileResult)) {
        userProfileResult = await postRequest({ uri: '/userProfile' });
      }
      setUserProfile(userProfileResult);
    } else {
      setUserProfile(undefined);
    }
    setLoadingUserProfile(false);
  };

  return (
    <Authenticator
      formFields={signUpFields}
      services={{
        async validateCustomSignUp(formData): Promise<ValidatorResult> {
          if (!formData.family_name) {
            return {
              family_name: 'Last Name is required',
            };
          } else if (!formData.given_name) {
            return {
              given_name: 'First Name is required',
            };
          }
        },
      }}
      components={components}
    >
      {({ signOut, user }) => {
        return (
          <UserAuthContext.Provider
            value={{
              userId: user?.userId,
              setUserId,
              signOut,
              loadUserProfile,
              loadingUserProfile,
              userProfile,
              setUserProfile,
            }}
          >
            {children}
          </UserAuthContext.Provider>
        );
      }}
    </Authenticator>
  );
};

export default UserAuthProvider;
