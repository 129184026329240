import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { DefaultTaskStatus, Task } from '../model/Task';
import Stack from 'react-bootstrap/esm/Stack';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  NavDropdown,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { UserAuthContext } from '../contexts/UserAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { TaskListContext } from '../contexts/TaskListContext';
import {
  TaskDueRange,
  checkTaskDueDate,
  formatDateToLocalString,
} from '../utils/DateTimeUtils';
import { isEmpty } from 'lodash';

interface Props {
  taskDueRange: TaskDueRange;
  task: Task;
}

const TaskRow: FC<Props> = ({ taskDueRange, task }) => {
  const { userProfile } = useContext(UserAuthContext);
  const { updateTask, recycleTask } = useContext(TaskListContext);

  const [showDueDayError, setShowDueDayError] = useState(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [taskRow, setTaskRow] = useState<Task>(task);
  const [isTaskTextInvalid, setTaskTextInvalid] = useState<boolean>(false);
  const [showEditTask, setShowEditTask] = useState<boolean>(false);
  const [taskText, setTaskText] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date | null>(
    task.dueDate ? new Date(task.dueDate) : null,
  );
  const [taskStatus, setTaskStatus] = useState<string>(task.taskStatus);

  const datePickerTarget = useRef(null);

  useEffect(() => {
    if (showDueDayError) {
      const timeout = setInterval(() => {
        setShowDueDayError(false);
      }, 2000);
      return () => clearInterval(timeout);
    }
  }, [showDueDayError]);

  const enableTaskEditing = () => {
    if (!showEditTask) {
      setTaskText(taskRow.task);
      setShowEditTask(!showEditTask);
    }
  };
  const updateTaskText = async () => {
    if (isEmpty(taskText)) {
      setTaskText(task.task);
      setShowEditTask(!showEditTask);
      setTaskTextInvalid(false);
      return;
    }
    setUpdating(true);
    if (taskText.trim() !== taskRow.task.trim()) {
      taskRow.task = taskText;
      setTaskRow(taskRow);
      await updateTask(taskDueRange, task);
    }
    setShowEditTask(!showEditTask);
    setUpdating(false);
  };

  const updateTaskStatus = async (newTaskStatus: string) => {
    setUpdating(true);
    setTaskStatus(newTaskStatus);
    taskRow.taskStatus = newTaskStatus;
    setTaskRow(taskRow);
    await updateTask(taskDueRange, task);
    setUpdating(false);
  };

  const updateDueDate = async (newDueDate: Date | null) => {
    setUpdating(true);
    const newDueDateStr = newDueDate
      ? formatDateToLocalString(new Date(newDueDate?.getTime()!))
      : null;
    if (taskRow.dueDate !== (newDueDateStr || undefined)) {
      if (checkTaskDueDate(newDueDateStr!) === TaskDueRange.OVER_DUE) {
        setDueDate(prop => prop);
        setShowDueDayError(true);
      } else {
        setDueDate(newDueDate);
        taskRow.dueDate = newDueDateStr || undefined;
        setTaskRow(taskRow);
        await updateTask(taskDueRange, task);
      }
    }
    setUpdating(false);
  };

  return (
    <StyledStack
      direction='horizontal'
      $taskColor={
        Object.values(DefaultTaskStatus).find(ts => ts.id === taskStatus)
          ?.bgColor
      }
    >
      <div style={{ width: '100px', paddingLeft: '5px' }}>
        <DropdownButton
          as={ButtonGroup}
          size='sm'
          title={
            userProfile?.taskGroups.find(({ id }) => id === taskRow?.taskGroup)
              ?.name || ''
          }
        >
          {userProfile?.taskGroups.map(tg => (
            <Dropdown.Item key={tg.id}>{tg.name}</Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div className='vr' />
      <div className='me-auto' style={{ width: '100%' }}>
        <div onDoubleClick={enableTaskEditing}>
          {showEditTask ? (
            <Form.Control
              required
              type='text'
              placeholder='Enter your task'
              value={taskText}
              isInvalid={isTaskTextInvalid}
              onChange={e => {
                setTaskText(e.target.value);
                setTaskTextInvalid(isEmpty(e.target.value));
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  updateTaskText();
                }
              }}
              onBlur={updateTaskText}
              size='sm'
              disabled={updating}
              autoFocus
            />
          ) : (
            taskRow?.task
          )}
        </div>
      </div>
      <StyledStatusContainer
        // style={{ padding: '.5rem' }}
        $bgColor={
          Object.values(DefaultTaskStatus).find(ts => ts.id === taskStatus)
            ?.bgColor
        }
        $txtColor={
          Object.values(DefaultTaskStatus).find(ts => ts.id === taskStatus)
            ?.txtColor
        }
      >
        <StyledStatusNavDropdown
          title={
            Object.values(DefaultTaskStatus).find(ts => ts.id === taskStatus)
              ?.name
          }
          id='task-status-nav-dropdown'
        >
          {Object.values(DefaultTaskStatus)
            .filter(ts => ts.id !== taskRow?.taskStatus)
            .map(ts => (
              <NavDropdown.Item
                key={ts.id}
                onClick={() => updateTaskStatus(ts.id)}
                style={{ backgroundColor: ts.bgColor, color: ts.txtColor }}
              >
                {ts.name}
              </NavDropdown.Item>
            ))}
        </StyledStatusNavDropdown>
      </StyledStatusContainer>
      <div style={{ width: '100px' }} ref={datePickerTarget}>
        <DatePicker
          selected={dueDate}
          onChange={newDate => updateDueDate(newDate)}
          dateFormat={taskRow.dueDate ? 'yyyy/MM/dd' : undefined}
          placeholderText='Pick a date'
          className='custom-datepicker'
          disabled={updating}
          onFocus={() => setShowDueDayError(false)}
        />
      </div>
      <Overlay
        target={datePickerTarget.current}
        show={showDueDayError}
        placement='top'
      >
        {props => (
          <Tooltip id='overlay-example' {...props}>
            Selected due date is already overdue.
          </Tooltip>
        )}
      </Overlay>
      <div className='vr' />
      <div style={{ width: '40px', paddingRight: '15px' }}>
        <StyledMoreFunNavDropdown
          title={<FontAwesomeIcon icon={faBars} />}
          id='nav-dropdown'
        >
          <NavDropdown.Item onClick={() => recycleTask(taskDueRange, taskRow)}>
            <FontAwesomeIcon icon={faTrashCan} />
            <span>Delete</span>
          </NavDropdown.Item>
        </StyledMoreFunNavDropdown>
      </div>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)<{ $taskColor?: string }>`
  gap: 0.7rem !important;
  margin-bottom: 2px;
  background-color: #f1f1f1;
  border-left: 5px solid ${props => props.$taskColor || '#BF4F74'};
  border-right: 5px solid ${props => props.$taskColor || '#BF4F74'};
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const StyledStatusContainer = styled.div<{
  $bgColor?: string;
  $txtColor?: string;
}>`
  position: relative;
  height: 40px;
  border-left: none;
  background-color: ${props => props.$bgColor || '#BF4F74'};
  color: ${props => props.$txtColor || '#BF4F74'};
  padding: 0.5rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 10px 20px 0;
    border-color: transparent ${props => props.$bgColor || '#BF4F74'}
      transparent transparent;
  }
`;

const StyledStatusNavDropdown = styled(NavDropdown)({
  width: '100px',
  textAlign: 'right',
});

const StyledMoreFunNavDropdown = styled(NavDropdown)`
  && > .dropdown-toggle::after {
    display: none;
  }
`;

export default TaskRow;
