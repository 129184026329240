import { createContext } from 'react';
import { Task } from '../model/Task';
import { noop } from 'lodash';
import { TaskDueRange } from '../utils/DateTimeUtils';

export interface TaskListContextValue {
  setTasks: (tasks: Task[]) => void;
  addTask: (task: Task) => void;
  updateTask: (dueRange: TaskDueRange, task: Task) => void;
  recycleTask: (dueRange: TaskDueRange, task: Task) => void;
  overdueTasks: Task[];
  setOverdueTasks: (tasks: Task[]) => void;
  dueTodayTasks: Task[];
  setDueTodayTasks: (tasks: Task[]) => void;
  dueThisWeekTasks: Task[];
  setDueThisWeekTasks: (tasks: Task[]) => void;
  dueNextWeekTasks: Task[];
  setDueNextWeekTasks: (tasks: Task[]) => void;
  futureTasks: Task[];
  setFutureTasks: (tasks: Task[]) => void;
  dueUnknownTasks: Task[];
  setDueUnknownTasks: (tasks: Task[]) => void;
}

const TaskListContext = createContext<TaskListContextValue>({
  setTasks: noop,
  addTask: noop,
  updateTask: noop,
  recycleTask: noop,
  overdueTasks: [],
  setOverdueTasks: noop,
  dueTodayTasks: [],
  setDueTodayTasks: noop,
  dueThisWeekTasks: [],
  setDueThisWeekTasks: noop,
  dueNextWeekTasks: [],
  setDueNextWeekTasks: noop,
  futureTasks: [],
  setFutureTasks: noop,
  dueUnknownTasks: [],
  setDueUnknownTasks: noop,
});

export { TaskListContext };
