import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCalendar, faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const SideNav = () => {
  const [expanded, setExpanded] = useState(true);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`sidebar ${expanded ? 'expanded' : 'collapsed'}`}
      style={{ height: '100vh' }}
    >
      <Navbar
        bg='dark'
        variant='dark'
        expand='lg'
        className='flex-column'
        style={{ height: '100vh', overflowY: 'hidden' }}
      >
        <Nav className='flex-column' style={{ width: expanded ? '130px' : '' }}>
          <Nav.Link onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} style={{ color: 'white' }} />
          </Nav.Link>
          <Nav.Link as={NavLink} to='/list'>
            <FontAwesomeIcon icon={faList} />{' '}
            {expanded ? <span>Tasks</span> : <></>}
          </Nav.Link>
          <Nav.Link as={NavLink} to='/calendar'>
            <FontAwesomeIcon icon={faCalendar} />{' '}
            {expanded ? <span>Calendar</span> : <></>}
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
};

export default SideNav;
