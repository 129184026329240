export const DefaultTaskStatus = {
  NOT_STARTED: {
    id: 'notStarted',
    name: 'Not Started',
    bgColor: '#6C757D',
    txtColor: '#FFFFFF',
  },
  BLOCKED: {
    id: 'blocked',
    name: 'Blocked',
    bgColor: '#DC3444',
    txtColor: '#FFFFFF',
  },
  IN_PROGRESS: {
    id: 'inProgress',
    name: 'Working',
    bgColor: '#FFC007',
    txtColor: '#212529',
  },
  COMPLETED: {
    id: 'completed',
    name: 'Completed',
    bgColor: '#14A2B8',
    txtColor: '#FFFFFF',
  },
  SUBMITTED: {
    id: 'submitted',
    name: 'Submitted',
    bgColor: '#28A745',
    txtColor: '#FFFFFF',
  },
};

export interface Task {
  id?: string;
  username: string;
  createDT?: number;
  taskGroup: string;
  task: string;
  dueDate?: string;
  taskStatus: string;
  notes?: string;
  deletedDT?: number;
}
