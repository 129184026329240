import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { fetchAuthSession } from '@aws-amplify/auth';

const APP_API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const getJwtToken = async (): Promise<string | undefined> => {
  const jwt = await fetchAuthSession();
  return jwt.tokens?.accessToken.toString();
};

const apiRequest = async ({
  method = 'GET',
  uri,
  body,
  params,
}: {
  method: string;
  uri: string;
  body?: any;
  params?: any;
}) => {
  const jwtToken = await getJwtToken();
  const uriString = queryString.stringify(params);

  const url = `${APP_API_DOMAIN}${uri}${!isEmpty(params) ? `?${uriString}` : ''}`;

  const response = await fetch(url, {
    method,
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Authorization': jwtToken ? jwtToken : '',
    },
    ...(method !== 'GET' && body ? { body: JSON.stringify(body) } : {}),
  });

  return await response;
};

export const getRequest = async ({
  uri,
  params,
}: {
  uri: string;
  params?: any;
}) => {
  const response = await apiRequest({
    method: 'GET',
    uri,
    params,
  });
  return await response.json();
};

export const postRequest = async ({
  uri,
  params,
  body,
}: {
  uri: string;
  params?: any;
  body?: any;
}) => {
  const response = await apiRequest({
    method: 'POST',
    uri,
    params,
    body,
  });
  return await response.json();
};

export const putRequest = async ({
  uri,
  params,
  body,
}: {
  uri: string;
  params?: any;
  body?: any;
}) => {
  const response = await apiRequest({
    method: 'PUT',
    uri,
    params,
    body,
  });
  return await response.json();
};
