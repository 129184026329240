import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { UserAuthContext } from '../contexts/UserAuthContext';

export const Header: React.FC = () => {
  const { signOut, userProfile } = useContext(UserAuthContext);

  return (
    <Navbar bg='dark' variant='dark' expand='lg' className='px-4'>
      <Navbar.Brand href='/'>InTempore</Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse
        id='basic-navbar-nav'
        className='justify-content-between'
      >
        <Nav className='mr-auto'></Nav>
        <Nav>
          <NavDropdown
            title={`Welcome, ${userProfile?.firstName}`}
            id='user-profile-dropdown'
          >
            {/* Add dropdown items for user actions (e.g., logout) */}
            <NavDropdown.Item href='#'>Profiles...</NavDropdown.Item>
            <NavDropdown.Divider />
            <center>
              <Button variant='danger' size='sm' onClick={signOut}>
                Signout
              </Button>
            </center>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
