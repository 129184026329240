import React, { FC, useState } from 'react';
import { Task } from '../model/Task';
import TaskRow from './TaskRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faAngleDown,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { Badge, Collapse, Stack } from 'react-bootstrap';
import { TaskDueRange } from '../utils/DateTimeUtils';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

interface Props {
  listIcon: IconDefinition;
  iconColor: string;
  titleColor: string;
  title: string;
  taskDueRange: TaskDueRange;
  tasks: Task[];
}

const TaskList: FC<Props> = ({
  listIcon,
  iconColor,
  titleColor,
  title,
  taskDueRange,
  tasks,
}) => {
  const [open, setOpen] = useState(true);

  const toggleListOpen = () => {
    setOpen(!open);
  };

  return (
    <StyledContainer>
      <StyledTitleContainer direction='horizontal'>
        <Stack
          onClick={toggleListOpen}
          gap={2}
          style={{ cursor: 'pointer' }}
          direction='horizontal'
        >
          <FontAwesomeIcon
            icon={listIcon}
            size='xl'
            style={{ color: iconColor }}
          />
          <StyledTitleTxt color={titleColor}>{title}</StyledTitleTxt>
          <Badge pill bg='primary' color={titleColor}>
            {tasks?.length || 0}
          </Badge>
        </Stack>
        {!isEmpty(tasks) && (
          <FontAwesomeIcon
            icon={open ? faAngleDown : faAngleRight}
            onClick={toggleListOpen}
            style={{ cursor: 'pointer' }}
          />
        )}
      </StyledTitleContainer>

      <Collapse in={open}>
        <TasksListContainer>
          {tasks?.map((task: Task) => (
            <TaskRow key={task.id} task={task} taskDueRange={taskDueRange} />
          ))}
        </TasksListContainer>
      </Collapse>
    </StyledContainer>
  );
};

const StyledContainer = styled.div({
  marginBottom: '20px',
});

const StyledTitleContainer = styled(Stack)({
  gap: '.5rem !important;',
  marginBottom: '5px',
});

const StyledTitleTxt = styled(Stack)<{ color: string }>`
  font-weight: bolder;
  color: ${props => props.color};
`;

const TasksListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export default TaskList;
