import React, { useContext, useEffect } from 'react';
import './App.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AppContainer from './views/AppContainer';
import { UserAuthContext } from './contexts/UserAuthContext';

function App() {
  const { route, user } = useAuthenticator(context => [context.authStatus]);
  const { userId, userProfile, loadUserProfile } = useContext(UserAuthContext);

  useEffect(() => {
    const load = async () => {
      switch (route) {
        case 'authenticated':
          await loadUserProfile(userId);
          break;
        case 'signOut':
          await loadUserProfile();
          break;
      }
    };
    load();
  }, [route]);

  return <AppContainer />;
}

export default App;
