import { createContext } from 'react';
import { noop } from 'lodash';
import { AuthEventData } from '@aws-amplify/ui';
import { UserProfile } from '../model/UserProfile';

export interface UserAuthContextValue {
  userId?: string;
  setUserId: (user: string | undefined) => void;
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
  loadUserProfile: (userId?: string) => void;
  loadingUserProfile: boolean;
  userProfile?: UserProfile;
  setUserProfile: (userProfile?: UserProfile) => void;
}

const UserAuthContext = createContext<UserAuthContextValue>({
  userId: undefined,
  setUserId: noop,
  signOut: noop,
  loadUserProfile: noop,
  loadingUserProfile: false,
  userProfile: undefined,
  setUserProfile: noop,
});

export { UserAuthContext };
