import { useState, useEffect } from 'react';
import { getRequest } from '../utils/Requests';

const useApiCall = (uri: string, params = {}) => {
  const [data, setData] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getRequest({ uri, params });
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uri]);

  return { data, error, loading };
};

export default useApiCall;
