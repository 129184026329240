import { useState } from 'react';
import { Task } from '../model/Task';
import { TaskListContext } from './TaskListContext';
import { TaskDueRange, checkTaskDueDate } from '../utils/DateTimeUtils';
import { postRequest, putRequest } from '../utils/Requests';
import moment from 'moment';

const TaskListProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [overdueTasks, setOverdueTasks] = useState<Task[]>([]);
  const [dueTodayTasks, setDueTodayTasks] = useState<Task[]>([]);
  const [dueThisWeekTasks, setDueThisWeekTasks] = useState<Task[]>([]);
  const [dueNextWeekTasks, setDueNextWeekTasks] = useState<Task[]>([]);
  const [futureTasks, setFutureTasks] = useState<Task[]>([]);
  const [dueUnknownTasks, setDueUnknownTasks] = useState<Task[]>([]);

  const populateTasks = (dueRange: TaskDueRange, task: Task) => {
    switch (dueRange) {
      case TaskDueRange.OVER_DUE:
        setOverdueTasks(prev =>
          [...prev, task].sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.TODAY:
        setDueTodayTasks(prev =>
          [...prev, task].sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.THIS_WEEK:
        setDueThisWeekTasks(prev =>
          [...prev, task].sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.NEXT_WEEK:
        setDueNextWeekTasks(prev =>
          [...prev, task].sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.FUTURE:
        setFutureTasks(prev =>
          [...prev, task].sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.UNKNOWN:
        setDueUnknownTasks(prev => [...prev, task]);
        break;
      default:
        setDueUnknownTasks(prev => [...prev, task]);
    }
  };

  const sortTasks = (dueRange: TaskDueRange) => {
    switch (dueRange) {
      case TaskDueRange.OVER_DUE:
        setOverdueTasks(prev =>
          prev.sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.TODAY:
        setDueTodayTasks(prev =>
          prev.sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.THIS_WEEK:
        setDueThisWeekTasks(prev =>
          prev.sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.NEXT_WEEK:
        setDueNextWeekTasks(prev =>
          prev.sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
      case TaskDueRange.FUTURE:
        setFutureTasks(prev =>
          prev.sort((a, b) => {
            const dateA = moment(a.dueDate, 'YYYY/MM/DD');
            const dateB = moment(b.dueDate, 'YYYY/MM/DD');
            return dateA.valueOf() - dateB.valueOf();
          }),
        );
        break;
    }
  };

  const removeTask = (dueRange: TaskDueRange, removeId: string) => {
    switch (dueRange) {
      case TaskDueRange.OVER_DUE:
        setOverdueTasks(prev => prev.filter(({ id }: Task) => id !== removeId));
        break;
      case TaskDueRange.TODAY:
        setDueTodayTasks(prev =>
          prev.filter(({ id }: Task) => id !== removeId),
        );
        break;
      case TaskDueRange.THIS_WEEK:
        setDueThisWeekTasks(prev =>
          prev.filter(({ id }: Task) => id !== removeId),
        );
        break;
      case TaskDueRange.NEXT_WEEK:
        setDueNextWeekTasks(prev =>
          prev.filter(({ id }: Task) => id !== removeId),
        );
        break;
      case TaskDueRange.FUTURE:
        setFutureTasks(prev => prev.filter(({ id }: Task) => id !== removeId));
        break;
      case TaskDueRange.UNKNOWN:
        setDueUnknownTasks(prev =>
          prev.filter(({ id }: Task) => id !== removeId),
        );
        break;
      default:
        setDueUnknownTasks(prev =>
          prev.filter(({ id }: Task) => id !== removeId),
        );
    }
  };

  const setTasks = (tasks: Task[]) => {
    tasks.forEach((task: Task) => {
      const dueRange = checkTaskDueDate(task.dueDate);
      populateTasks(dueRange, task);
    });
  };

  const addTask = async (task: Task): Promise<TaskDueRange> => {
    const dueRange = checkTaskDueDate(task.dueDate);
    const createResponse = await postRequest({ uri: '/task', body: task });
    populateTasks(dueRange, createResponse);
    return dueRange;
  };

  const updateTask = async (dueRange: TaskDueRange, task: Task) => {
    const updateResponse = await putRequest({ uri: '/task', body: task });
    const newDueRange = checkTaskDueDate(task.dueDate);
    removeTask(dueRange, task.id!);
    populateTasks(newDueRange, task);
    return updateResponse;
  };

  const recycleTask = async (dueRange: TaskDueRange, task: Task) => {
    task.deletedDT = new Date().getTime();
    const recycleResponse = await putRequest({ uri: '/task', body: task });
    removeTask(dueRange, task.id!);

    return recycleResponse;
  };

  return (
    <TaskListContext.Provider
      value={{
        setTasks,
        addTask,
        updateTask,
        recycleTask,
        overdueTasks,
        setOverdueTasks,
        dueTodayTasks,
        setDueTodayTasks,
        dueThisWeekTasks,
        setDueThisWeekTasks,
        dueNextWeekTasks,
        setDueNextWeekTasks,
        futureTasks,
        setFutureTasks,
        dueUnknownTasks,
        setDueUnknownTasks,
      }}
    >
      {children}
    </TaskListContext.Provider>
  );
};

export default TaskListProvider;
