import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Overlay,
  Spinner,
  Stack,
  Toast,
  ToastContainer,
  Tooltip,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { UserAuthContext } from '../contexts/UserAuthContext';
import { TaskListContext } from '../contexts/TaskListContext';
import {
  TaskDueRange,
  checkTaskDueDate,
  formatDateToLocalString,
} from '../utils/DateTimeUtils';
import { DefaultTaskStatus } from '../model/Task';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const QuickAddTask: FC = () => {
  const [adding, setAdding] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [taskText, setTaskText] = useState<string>('');
  const [isTaskTextInvalid, setTaskTextInvalid] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
  const [showDueDayError, setShowDueDayError] = useState(false);
  const { userId, userProfile } = useContext(UserAuthContext);
  const { addTask } = useContext(TaskListContext);

  const datePickerTarget = useRef(null);

  useEffect(() => {
    if (showDueDayError) {
      const timeout = setInterval(() => {
        setShowDueDayError(false);
      }, 2000);
      return () => clearInterval(timeout);
    }
  }, [showDueDayError]);

  const createTask = async () => {
    if (isEmpty(taskText)) {
      setTaskTextInvalid(true);
      return;
    }

    const newDueDateStr = dueDate
      ? formatDateToLocalString(dueDate)
      : undefined;

    if (checkTaskDueDate(newDueDateStr!) === TaskDueRange.OVER_DUE) {
      setShowDueDayError(true);
      return;
    }

    setAdding(true);

    const newTask = {
      dueDate: newDueDateStr,
      task: taskText,
      taskGroup: userProfile?.taskGroups[0].id!,
      taskStatus: DefaultTaskStatus.NOT_STARTED.id,
      username: userId!,
    };
    await addTask(newTask);
    setShow(true);
    setDueDate(undefined);
    setTaskText('');
    setAdding(false);
  };

  return (
    <StyledContainer>
      <StyledTitleContainer direction='horizontal' gap={2}>
        <FontAwesomeIcon icon={faPenToSquare} size='xl' color='#017BFE' />
        <StyledTitleTxt>Add a new task here</StyledTitleTxt>
      </StyledTitleContainer>
      <StyledStack direction='horizontal' gap={3}>
        <div className='p-2' style={{ width: '100px' }}>
          <DropdownButton
            as={ButtonGroup}
            size='sm'
            title={userProfile?.taskGroups[0].name || ''}
            disabled={adding}
          >
            {userProfile?.taskGroups.map(tg => (
              <Dropdown.Item key={tg.id}>{tg.name}</Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div className='me-auto' style={{ width: '100%' }}>
          <Form.Control
            required
            type='text'
            placeholder='Enter your task'
            value={taskText}
            isInvalid={isTaskTextInvalid}
            onChange={e => {
              setTaskTextInvalid(false);
              setTaskText(e.target.value);
            }}
            size='sm'
            style={{ width: '100%' }}
            disabled={adding}
          />
        </div>
        <div style={{ width: '100px' }} ref={datePickerTarget}>
          <DatePicker
            selected={dueDate}
            onChange={e => {
              setDueDate(e ? new Date(e?.getTime()!) : undefined);
            }}
            dateFormat={dueDate ? 'yyyy/MM/dd' : undefined}
            placeholderText='Pick a date'
            className='custom-datepicker'
            disabled={adding}
          />
        </div>
        <Overlay
          target={datePickerTarget.current}
          show={showDueDayError}
          placement='top'
        >
          {props => (
            <Tooltip id='overlay-example' {...props}>
              Selected due date is already overdue.
            </Tooltip>
          )}
        </Overlay>
        <div style={{ width: '100px' }}>
          <Button
            onClick={createTask}
            disabled={adding}
            style={{ width: '60px' }}
            size='sm'
          >
            {adding ? (
              <Spinner
                as='span'
                size='sm'
                animation='border'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Add'
            )}
          </Button>
        </div>
      </StyledStack>

      <ToastContainer position='bottom-end' style={{ zIndex: 1 }}>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
          bg='success'
        >
          <Toast.Header>
            <FontAwesomeIcon className='me-auto' icon={faThumbsUp} />
          </Toast.Header>
          <Toast.Body className={'text-white'}>New task added</Toast.Body>
        </Toast>
      </ToastContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div({
  marginBottom: '20px',
});

const StyledTitleContainer = styled(Stack)({
  gap: '.5rem !important;',
  marginBottom: '5px',
});

const StyledTitleTxt = styled(Stack)({
  fontWeight: 'bolder',
  color: '#017BFE',
});

const StyledStack = styled(Stack)<{ $taskColor?: string }>({
  gap: '0.7rem !important',
  marginBottom: '1px',
  backgroundColor: '#f1f1f1',
  borderLeft: '5px solid blue',
  borderRight: '5px solid blue',
  borderRadius: '4px',
});

export default QuickAddTask;
