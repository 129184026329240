import { Col, Container, Row, Spinner } from 'react-bootstrap';

const LoadingView: React.FC = () => {
  return (
    <Container
      fluid
      className='d-flex justify-content-center align-items-center vh-100'
    >
      <Row>
        <Col>
          <div className='text-center'>
            <Spinner
              animation='grow'
              variant='primary'
              size='sm'
              style={{
                animationDelay: '0.1s',
              }}
            />
            <Spinner
              animation='grow'
              variant='secondary'
              size='sm'
              style={{
                animationDelay: '0.1s',
              }}
            />
            <Spinner
              animation='grow'
              variant='success'
              size='sm'
              style={{
                animationDelay: '0.2s',
              }}
            />
            <Spinner
              animation='grow'
              variant='danger'
              size='sm'
              style={{
                animationDelay: '0.3s',
              }}
            />
            <Spinner
              animation='grow'
              variant='warning'
              size='sm'
              style={{
                animationDelay: '0.4s',
              }}
            />
            <Spinner
              animation='grow'
              variant='info'
              size='sm'
              style={{
                animationDelay: '0.5s',
              }}
            />
            <Spinner
              animation='grow'
              variant='light'
              size='sm'
              style={{
                animationDelay: '0.6s',
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingView;
