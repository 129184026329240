import moment from 'moment';

export enum TaskDueRange {
  OVER_DUE,
  TODAY,
  THIS_WEEK,
  NEXT_WEEK,
  FUTURE,
  UNKNOWN,
}

export const checkTaskDueDate = (dateStr?: string): TaskDueRange => {
  if (!dateStr) {
    return TaskDueRange.UNKNOWN;
  }

  const givenDate = moment(dateStr, 'YYYY/MM/DD');

  const today = moment();

  if (givenDate.isBefore(today, 'day')) {
    return TaskDueRange.OVER_DUE;
  }

  if (givenDate.isSame(today, 'day')) {
    return TaskDueRange.TODAY;
  }

  const currentWeekStart = today.clone().startOf('isoWeek');
  const currentWeekEnd = today.clone().endOf('isoWeek');
  const nextWeekEnd = currentWeekEnd.clone().add(1, 'day').endOf('isoWeek');

  if (
    givenDate.isAfter(currentWeekStart, 'day') &&
    givenDate.isSameOrBefore(currentWeekEnd, 'day')
  ) {
    return TaskDueRange.THIS_WEEK;
  }

  if (
    givenDate.isAfter(currentWeekEnd, 'day') &&
    givenDate.isSameOrBefore(nextWeekEnd, 'day')
  ) {
    return TaskDueRange.NEXT_WEEK;
  }
  return TaskDueRange.FUTURE;
};

export const formatDateToLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
};
